import React from 'react'
import './Login.css'
import Config from './config'
import Apis from './http'
import { loginAction } from './user';
import config from './config';
import { read, saveMulti } from './storage'
import jwtDecode from 'jwt-decode'

let codeInterval
class XPlatformLogin extends React.Component {
  constructor() {
    super()
    this.state = {
      chkBtnAct: false,
      numberSend: true,
      waitNext: false,
      waitNumber: 60,
      loginSend: false,
      phone: '',
      phoneMsg: '',
      code: '',
      codeMsg: '',
      hintNote: '',
      hintOn: false,
      formShow: false
    }
  }

  componentWillMount () {
    const access_token = read('user.access_token')
    const refresh_token = read('user.refresh_token')
    if(access_token) {
      let userInfo = jwtDecode(access_token)
      let current = (new Date()).getTime() / 1000
      if(current < userInfo.exp) {
        this.getURLs(access_token)
        // window.location.href="/xprd"
      } else {
        // access_token expired
        Apis.refresh({ 'refreshToken': refresh_token, 'clientId': 'xilife.xdp.sms.code.login' })
          .then((res) => {
            saveMulti([{
              key: 'user.access_token',
              value: res.data.access_token
            },
            {
              key: 'user.refresh_token',
              value: res.data.refresh_token
            },
            ]);
            this.getURLs(res.data.access_token)

            // window.location.href="/xprd";
            
          }).catch((err) => {
            this.setState({
              formShow: true
            })
          })
        // this.setState({
        //   formShow: true
        // })
      }
    } else {
      this.setState({
        formShow: true
      })
    }
  }

  hintMsg = async (msg) => {
    await this.setState({
      hintNote: msg,
      hintOn: true
    })
  }

  hintShut = async () => {
    await this.setState({
      hintNote: '',
      hintOn: false
    })
  }

  checkFormat(number) {
    const check = /^1\d{10}$/
    return check.test(number)
  }

  handlePhone = async (value) => {
    await this.setState({
      phone: value,
      phoneMsg: ''
    })
    let pass = this.checkFormat(this.state.phone)
    await this.setState({
      chkBtnAct: pass
    })
  }

  handleClear = async () => {
    await this.setState({
      phone: '',
      // phoneMsg: ''
    })
    this.phoneRef.focus()
  }

  handleCode = async (value) => {
    await this.setState({
      code: value,
      codeMsg: ''
    })
  }

  handleInputFocus = async (target) => {
    if(!target.classList.contains('focus')) {
      target.classList.add('focus')
    }
    if(target.classList.contains('err')) {
      target.classList.remove('err')
    }
  }

  handlePhoneBlur = async (target) => {
    if(target.classList.contains('focus')) {
      target.classList.remove('focus')
    }
    // 没有填手机号
    if (!this.state.phone) {
      this.setState({
        phoneMsg: '*请填写手机号码'
      })
      if(!target.classList.contains('err')) {
        target.classList.add('err')
      }
      return
    }
    // 手机号格式不对
    let pass = this.checkFormat(this.state.phone)
    if (!pass) {
      this.setState({
        phoneMsg: '*输入格式不正确'
      })
      if(!target.classList.contains('err')) {
        target.classList.add('err')
      }
      return
    }
  }

  handleCodeBlur = async (target) => {
    if(target.classList.contains('focus')) {
      target.classList.remove('focus')
    }
    if(!this.state.code) {
      this.setState({
        codeMsg: '*请填写验证码'
      })
      return
    }
  }

  processCodeState(val) {
    if (val === 0) {
      window.clearInterval(codeInterval)
      this.setState({
        waitNext: false,
        waitNumber: 60
      })
    } else {
      this.setState({
        waitNumber: val - 1
      })
    }
  }

  getCheckCode = async () => {
    // 刚发送了验证码，等60s
    if (this.state.waitNext) {
      return;
    }
    // 没有填手机号
    if (!this.state.phone) {
      this.setState({
        phoneMsg: '*请填写手机号码'
      })
      return
    }
    // 手机号格式不对
    let pass = this.checkFormat(this.state.phone)
    if (!pass) {
      this.setState({
        phoneMsg: '*输入格式不正确'
      })
      return
    }
    try {
      this.setState({
        waitNext: true,
      })
      codeInterval = window.setInterval(() => {
        this.processCodeState(this.state.waitNumber)
      }, 1000)
      await Apis.getCode({ mobile: this.state.phone })
      await this.setState({
        numberSend: true
      })
      this.checkRef.focus()
    } catch (err) {
      this.processCodeState(0)
      await this.hintMsg(err.response.data[0])
      // pureHint(err.response.data[0])
    }
  }

  submitLogin = async () => {
    if (!this.state.phone) {
      this.setState({
        phoneMsg: '*请填写手机号码'
      })
      return
    }
    if(!this.state.numberSend) {
      this.setState({
        codeMsg: '*请填写验证码'
      })
      return;
    }
    if (!this.state.code) {
      this.setState({
        codeMsg: '*请填写验证码'
      })
      return
    }
    let pass = this.checkFormat(this.state.phone)
    if (!pass) {
      this.setState({
        phoneMsg: '*输入格式不正确'
      })
      return
    } 
    let params = {
      mobile: this.state.phone,
      code: this.state.code
    }
    try {
      await this.setState({
        loginSend: true
      })
      await loginAction(params);
      const access_token = read('user.access_token')
      this.getURLs(access_token)
      // pureHint('登录成功');
     
    } catch(err) {
      this.processCodeState(0)
      if(err.response.data[0]) {
        this.hintMsg(err.response.data[0])
      } else {
        this.hintMsg('登录失败')
      }
    } finally {
      await this.setState({
        loginSend: false
      })
    }
  }

  getURLs =(token)=>{
    Apis.getURLs(token).then((res)=>{
      console.log(res,'res');
      if(res.data.length>0){
        let system = localStorage.getItem("system");
        let target = res.data.find(v=>v.id===system);
        if(target){
          window.location.href="/"+target.url
        }else{
          window.location.href="/"+res.data[0].url
        }
        // window.location.href="/"+res.data[0].url
      }
    }).catch((err)=>{
      console.log(err,'rr')
    })
  }
  render() {
    const viceText = this.state.waitNext ? `${this.state.waitNumber}S后重新获取验证码` : `获取验证码`
    const viceAct = this.state.chkBtnAct && !this.state.waitNext ? `active` : ''
    const codeAct = this.state.waitNext ? 'wait' : ''
    const submitAct = this.state.numberSend ? 'active' : ''
    const loginText = this.state.loginSend ? '登录中' : '登录'
    return (
      <section className="login-wrap" style={ { display: this.state.formShow ? 'flex' : 'none' } }>
        <div className="login-hold">
          <div className="logo-hold">
            <p className="logo-ank" href="">
              <img alt="xilife-xdp" src={`${Config.staticUrl}/common/images/logo.svg`} />
            </p>
          </div>
          <p className="title-hold">喜鹊数字化平台</p>
          <form className="login-form" onKeyDown={ (e) => { if(e.keyCode === 13) { this.submitLogin() } } }>
            <div className="form-g">
              <div className="inp-hold">
                <input type="number" className="real-inp" max="20000000000" placeholder="手机号码" ref={(input) => { this.phoneRef = input; }} value={this.state.phone} onChange={(e) => { this.handlePhone(e.target.value) }} onFocus={ (e) => { this.handleInputFocus(e.target.parentElement) } } onBlur={ (e) => { this.handlePhoneBlur(e.target.parentElement) } } />
                {
                  this.state.phone.length > 0 ?
                    <p className="clear-hold" onClick={() => { this.handleClear() }}>
                      <span className="clear-cross">&#x2715;</span>
                    </p> : null
                }
              </div>
              <p className="err-para">{this.state.phoneMsg}</p>
            </div>
            <div className="form-g">
              <div className={`inp-hold-vice ${codeAct}`}>
                <input type="number" className="real-inp" max="999999" placeholder="验证码" ref={(input) => { this.checkRef = input; }} onChange={(e) => { this.handleCode(e.target.value) }} onFocus={ (e) => { this.handleInputFocus(e.target.parentElement) } } onBlur={ (e) => { this.handleCodeBlur(e.target.parentElement) } } />
                <p className={`vice-btn ${viceAct}`} onClick={() => { this.getCheckCode() }}>{viceText}</p>
              </div>
              <p className="err-para">{this.state.codeMsg}</p>
            </div>
            <div className={`submit-btn ${submitAct}`} onClick={ () => { this.submitLogin() } }>
              <span>{ loginText }</span>
              {
                this.state.loginSend ? 
                  <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  : null
              }
            </div>
          </form>
        </div>
        {
          this.state.hintOn ? 
          <div className="modal-shade" onClick={ () => { this.hintShut() } }>
            <div className="modal-body" onClick={ (e) => { e.stopPropagation() } }>
              <p className="icon-type-container"><img src={`${config.assertUrl}/common/images/info.png`} alt=""/></p>
              {/* <p className="prompt-tit">提示</p> */}
              <p className="prompt-msg">{ this.state.hintNote }</p>
              <p className="re-operate" onClick={ () => { this.hintShut() } }>确 定</p>
            </div>
          </div> : null
        }
        
      </section>
    )
  }
}

export default XPlatformLogin