import React, { Component } from 'react';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import './App.css';
import Login from './Login'
class App extends Component {
  render() {
    return (
      <div className="login-app">
        <Login></Login>
      </div>
    );
  }
}

export default App;
