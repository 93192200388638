export default {
  // platformApi: 'https://uat-api.xdp.xi-life.cn',
  // apiRoot: 'https://uat-api.xdp.xi-life.cn',
  // staticUrl: "https://uat-cdn.xdp.xi-life.cn",
  // assertUrl: "https://uat-cdn.xdp.xi-life.cn",
  // loginUrl: 'http://192.168.16.175',
  platformApi: 'https://apic.xdp.xi-life.cn/',
  apiRoot: 'https://apic.xdp.xi-life.cn/',
  staticUrl:"https://cdn.xdp.xi-life.cn",
  assertUrl:"https://cdn.xdp.xi-life.cn"
}