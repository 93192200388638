import Apis from '../http'

export function login (mobile, code) {
  return new Promise((resolve, reject) => {
    Apis.login({
      mobile: mobile,
      code: code,
      clientId: 'xilife.xdp.sms.code.login'
    })
    .then(res => {
      if(res.status === 200){
        resolve(res.data)
      } else {
        reject(new Error('414'))
      }
    })
    .catch((err) => {
      reject(err)
    })
  })
};
