import { login } from './user.api'
import { saveMulti, clearMulti } from '../storage'
import {
  // STORE_KEY_MOBILE,
  // STORE_KEY_ROLE,
  STORE_KEY_ACCESS_TOKEN,
  STORE_KEY_REFRESH_TOKEN,
  // STORE_KEY_EMAIL,
  // STORE_KEY_USER_PHOTO
} from '../constants'
import jwtDecode from 'jwt-decode'

export function loginAction(payload) {
  return new Promise((resolve, reject) => {
    login(payload.mobile, payload.code)
      .then(data => {
        let userResolve = jwtDecode(data.access_token)

        let userInfo = {
          mobile: userResolve.Mobile,
          role: userResolve.Role,
          access_token: data.access_token,
          refresh_token: data.refresh_token,
          // email: data.user.email,
          // nickName: data.user.nickName,
          // userPhoto: data.user.userPhoto,
          // mobilePin: data.user.mobilePin
        }

        saveMulti(
          [
          // {
          //   key: STORE_KEY_MOBILE,
          //   value: userInfo.mobile
          // },
          // {
          //   key: STORE_KEY_ROLE,
          //   value: userInfo.role
          // },
          {
            key: STORE_KEY_ACCESS_TOKEN,
            value: userInfo.access_token
          },
          {
            key: STORE_KEY_REFRESH_TOKEN,
            value: userInfo.refresh_token
          },
            // {
            //   key: STORE_KEY_EMAIL,
            //   value: userInfo.email
            // }, 
            // {
            //   key: STORE_KEY_USER_PHOTO,
            //   value: userInfo.photo
            // }
          ])
        resolve()
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function refreToken(payload) {
  saveMulti([{
    key: STORE_KEY_ACCESS_TOKEN,
    value: payload.access_token
  }, {
    key: STORE_KEY_REFRESH_TOKEN,
    value: payload.refresh_token
  }])
}

export function logout() {
  clearMulti([
    // STORE_KEY_MOBILE,
    // STORE_KEY_ROLE,
    STORE_KEY_ACCESS_TOKEN,
    STORE_KEY_REFRESH_TOKEN,
    // STORE_KEY_EMAIL,
    // STORE_KEY_USER_PHOTO 
  ])
}